export const stations = [
  {
    id: 1,
    name: "BBC Radio 1",
    url: "http://as-hls-ww-live.akamaized.net/pool_904/live/ww/bbc_radio_one/bbc_radio_one.isml/bbc_radio_one-audio%3d96000.norewind.m3u8",
    image: "/assets/radio1.png",
    country: "UK",
    favorite: true,
  },
  {
    id: 2,
    name: "Capital FM",
    url: "http://media-ice.musicradio.com/CapitalUKMP3",
    image: "/assets/capital.jpg",
    country: "UK",
    favorite: true,
  },
  {
    id: 3,
    name: "Capital Xtra",
    url: "http://media-ice.musicradio.com/CapitalXTRANational",
    image: "/assets/capital-xtra-uk.webp",
    country: "UK",
    favorite: true,
  },
  {
    id: 4,
    name: "Kiss FM UK",
    url: "https://edge-bauerall-01-gos2.sharp-stream.com/kissnational.aac",
    image: "/assets/kiss_fm.jpeg",
    country: "UK",
    favorite: true,
  },
  {
    id: 5,
    name: "Heart FM",
    url: "https://media-ssl.musicradio.com/HeartLondon",
    image: "/assets/heart.jpg",
    country: "UK",
    favorite: true,
  },
  {
    id: 6,
    name: "Heart Dance",
    url: "https://media-ssl.musicradio.com/HeartDance",
    image: "/assets/heart-dance.png",
    country: "UK",
    favorite: false,
  },
  {
    id: 7,
    name: "Heart UK",
    url: "https://media-ssl.musicradio.com/HeartUK",
    image: "/assets/heart-UK.png",
    country: "UK",
    favorite: false,
  },
  {
    id: 8,
    name: "Smooth Radio",
    url: "https://media-ssl.musicradio.com/SmoothUK",
    image: "/assets/smooth.jpg",
    country: "UK",
    favorite: true,
  },
  {
    id: 9,
    name: "Radio X",
    url: "https://media-ssl.musicradio.com/RadioXLondon",
    image: "/assets/radioX.png",
    country: "UK",
    favorite: false,
  },
  {
    id: 10,
    name: "Absolute Radio",
    url: "https://edge-bauerall-01-gos2.sharp-stream.com/absoluteradiohigh.aac",
    image: "/assets/absolute-radio.png",
    country: "UK",
    favorite: false,
  },
  {
    id: 11,
    name: "Absolute 80s",
    url: "https://edge-bauerall-01-gos2.sharp-stream.com/absolute80s.aac",
    image: "/assets/absolute-radio-80s.png",
    country: "UK",
    favorite: false,
  },
  {
    id: 12,
    name: "Absolute 90s",
    url: "https://edge-bauerall-01-gos2.sharp-stream.com/absolute90s.aac",
    image: "/assets/absolute-radio-90s.png",
    country: "UK",
    favorite: false,
  },
  {
    id: 13,
    name: "Absolute Classic Rock",
    url: "https://edge-bauerall-01-gos2.sharp-stream.com/absoluteclassicrock.aac",
    image: "/assets/absolute-classic-rock.png",
    country: "UK",
    favorite: false,
  },
  {
    id: 14,
    name: "BBC Radio 2",
    url: "https://as-hls-ww-live.akamaized.net/pool_904/live/ww/bbc_radio_two/bbc_radio_two.isml/bbc_radio_two-audio%3d96000.norewind.m3u8",
    image: "/assets/radio2.png",
    country: "UK",
    favorite: false,
  },
  {
    id: 15,
    name: "BBC Radio 1Xtra",
    url: "https://as-hls-ww-live.akamaized.net/pool_904/live/ww/bbc_1xtra/bbc_1xtra.isml/bbc_1xtra-audio%3d96000.norewind.m3u8",
    image: "/assets/bbc-radio-1xtra.jpg",
    country: "UK",
    favorite: false,
  },
  {
    id: 16,
    name: "Hits Radio UK",
    url: "https://cast.tunzilla.com/http://edge-bauerall-01-gos2.sharp-stream.com/net1london.aac",
    image: "/assets/hits-radio.png",
    country: "UK",
    favorite: false,
  },
  {
    id: 17,
    name: "Hits Radio",
    url: "https://playerservices.streamtheworld.com/api/livestream-redirect/977_HITS_SC",
    image: "/assets/hitsRadio.jpg",
    country: "UK",
    favorite: false,
  },
  {
    id: 18,
    name: "Pop Hits",
    url: "https://pophits128-thehitsnetwork.radioca.st/http://pollux.shoutca.st:8939/1",
    image: "/assets/pophits.png",
    country: "UK",
    favorite: false,
  },
  {
    id: 19,
    name: "Total Hits",
    url: "https://totalhits128-thehitsnetwork.radioca.st/http://pollux.shoutca.st:8882/1",
    image: "/assets/total-hits.png",
    country: "UK",
    favorite: false,
  },
  {
    id: 20,
    name: "Hot Hits",
    url: "https://443-1.autopo.st/54/stream.mp3",
    image: "/assets/hot-hits.png",
    country: "UK",
    favorite: false,
  },
  {
    id: 21,
    name: "Fresh Hits",
    url: "https://freshuk128-thehitsnetwork.radioca.st/http://pollux.shoutca.st:8886/1",
    image: "/assets/fresh-hits.png",
    country: "UK",
    favorite: false,
  },
  // Romanian stations
  {
    id: 22,
    name: "Radio ZU",
    url: "https://live4ro.antenaplay.ro/radiozu/radiozu-48000.m3u8",
    image: "/assets/radioZu.jpeg",
    country: "RO",
    favorite: true,
  },
  {
    id: 23,
    name: "Kiss FM",
    url: "https://live.kissfm.ro/kissfm.aacp",
    image: "/assets/kissfm.png",
    country: "RO",
    favorite: true,
  },
  {
    id: 24,
    name: "Magic FM",
    url: "https://live.magicfm.ro/magicfm.aacp",
    image: "/assets/magicfm.jpg",
    country: "RO",
    favorite: true,
  },
  {
    id: 25,
    name: "Europa FM",
    url: "https://astreaming.edi.ro:8443/EuropaFM_aac",
    image: "/assets/europafmro.png",
    country: "RO",
    favorite: true,
  },
  {
    id: 26,
    name: "Pro FM",
    url: "https://edge126.rcs-rds.ro/profm/profm.mp3",
    image: "/assets/profmro.png",
    country: "RO",
    favorite: true,
  },
  {
    id: 27,
    name: "Virgin Radio",
    url: "https://astreaming.edi.ro:8443/VirginRadio_aac",
    image: "/assets/virgin.webp",
    country: "RO",
    favorite: true,
  },
  {
    id: 28,
    name: "Radio Guerrilla",
    url: "https://live.guerrillaradio.ro:8443/guerrilla.aac",
    image: "/assets/radioG.jpg",
    country: "RO",
    favorite: false,
  },
  {
    id: 29,
    name: "Rock FM",
    url: "https://live.rockfm.ro/rockfm.aacp",
    image: "/assets/rock.png",
    country: "RO",
    favorite: false,
  },
  {
    id: 30,
    name: "Digi FM",
    url: "https://edge76.rcs-rds.ro/digifm/digifm.mp3",
    image: "/assets/digifm.png",
    country: "RO",
    favorite: false,
  },
];
