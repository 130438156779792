import React, {
  useState,
  useRef,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import Hls from "hls.js";
import "./App.css";
import {
  Play,
  Pause,
  Loader,
  SkipBack,
  SkipForward,
  StarOffIcon,
} from "lucide-react";
import { stations } from "./stations.js";

function App() {
  const [allStations, setAllStations] = useState(stations);
  const [currentStation, setCurrentStation] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false); // Track if audio is playing
  const [loadingStation, setLoadingStation] = useState(null);
  const [showMoreUK, setShowMoreUK] = useState(false);
  const [showMoreRO, setShowMoreRO] = useState(false);
  const [removingStation, setRemovingStation] = useState(null);
  const [colors, setColors] = useState(["#fff", "#fff", "#fff"]); // Gradient colors state
  const audioRef = useRef(null);
  const hlsRef = useRef(null);
  const imageRef = useRef(null);

  const extractColorsFromImage = useCallback(() => {
    if (imageRef.current && window.ColorThief) {
      const colorThief = new window.ColorThief();
      const palette = colorThief.getPalette(imageRef.current, 3); // Get the top 3 colors from the image
      if (palette.length >= 3) {
        setColors([palette[0], palette[1], palette[2]].map(rgbArrayToHex)); // Set the colors as hex values
      }
    }
  }, []);

  // Utility function to convert RGB array to HEX
  const rgbArrayToHex = (rgbArray) => {
    return (
      "#" +
      rgbArray
        .map((val) => {
          const hex = val.toString(16);
          return hex.length === 1 ? "0" + hex : hex;
        })
        .join("")
    );
  };

  // Helper function to load favorites from localStorage
  const loadFavoritesFromStorage = () => {
    const storedFavorites = localStorage.getItem("favoriteStations");
    if (storedFavorites) {
      return JSON.parse(storedFavorites);
    }
    return null; // No favorites stored yet
  };

  // Helper function to save favorites to localStorage
  const saveFavoritesToStorage = (favorites) => {
    localStorage.setItem("favoriteStations", JSON.stringify(favorites));
  };

  const [favoriteStations, setFavoriteStations] = useState(() => {
    const storedFavorites = loadFavoritesFromStorage();
    if (storedFavorites) {
      return storedFavorites;
    } else {
      return {
        UK: allStations.filter(
          (station) => station.country === "UK" && station.favorite
        ),
        RO: allStations.filter(
          (station) => station.country === "RO" && station.favorite
        ),
      };
    }
  });

  useEffect(() => {
    if (currentStation && isPlaying) {
      extractColorsFromImage(); // Extract colors when a station is playing
    }
  }, [currentStation, isPlaying, extractColorsFromImage]);

  const stopPreviousStream = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.removeAttribute("src");
      audioRef.current.load();
    }

    if (hlsRef.current) {
      hlsRef.current.destroy();
      hlsRef.current = null;
    }

    setIsPlaying(false);
  };

  const playStation = useCallback(
    (station) => {
      setLoadingStation(station.id);

      if (currentStation === station.id && isPlaying) {
        // If the current station is already playing, pause it
        stopPreviousStream();
        setIsPlaying(false); // Set the state to paused
        setLoadingStation(null);
      } else {
        stopPreviousStream();
        setCurrentStation(station.id);

        if (station.url.endsWith(".m3u8")) {
          if (Hls.isSupported()) {
            const hls = new Hls();
            hls.loadSource(station.url);
            hls.attachMedia(audioRef.current);

            hls.on(Hls.Events.MANIFEST_PARSED, () => {
              audioRef.current
                .play()
                .then(() => {
                  setIsPlaying(true);
                  setLoadingStation(null);
                })
                .catch((e) => {
                  console.error("Error while playing HLS stream", e);
                  setLoadingStation(null);
                });
            });

            hlsRef.current = hls;
          } else if (
            audioRef.current.canPlayType("application/vnd.apple.mpegurl")
          ) {
            audioRef.current.src = station.url;
            audioRef.current
              .play()
              .then(() => {
                setIsPlaying(true);
                setLoadingStation(null);
              })
              .catch((e) => {
                console.error("Error while playing HLS audio", e);
                setLoadingStation(null);
              });
          }
        } else {
          const newAudio = new Audio(station.url);
          newAudio
            .play()
            .then(() => {
              audioRef.current = newAudio;
              setIsPlaying(true);
              setLoadingStation(null);
            })
            .catch((error) => {
              console.error("Error during playback", error);
              setLoadingStation(null);
            });

          newAudio.addEventListener("error", (e) => {
            console.error("Error loading the audio stream", e);
            setLoadingStation(null);
          });
        }
      }
    },
    [currentStation, isPlaying]
  );

  const displayedUKStations = showMoreUK
    ? allStations.filter((station) => station.country === "UK")
    : favoriteStations.UK;

  const displayedROStations = showMoreRO
    ? allStations.filter((station) => station.country === "RO")
    : favoriteStations.RO;

  const combinedDisplayedStations = useMemo(
    () => [...displayedUKStations, ...displayedROStations],
    [displayedUKStations, displayedROStations]
  );

  const nextStation = useCallback(() => {
    const currentIndex = combinedDisplayedStations.findIndex(
      (s) => s.id === currentStation
    );
    const nextIndex = (currentIndex + 1) % combinedDisplayedStations.length;
    playStation(combinedDisplayedStations[nextIndex]);
  }, [currentStation, playStation, combinedDisplayedStations]);

  const previousStation = useCallback(() => {
    const currentIndex = combinedDisplayedStations.findIndex(
      (s) => s.id === currentStation
    );
    const prevIndex =
      (currentIndex - 1 + combinedDisplayedStations.length) %
      combinedDisplayedStations.length;
    playStation(combinedDisplayedStations[prevIndex]);
  }, [currentStation, playStation, combinedDisplayedStations]);

  useEffect(() => {
    if ("mediaSession" in navigator) {
      const currentStationData = allStations.find(
        (s) => s.id === currentStation
      );
      const imageType = currentStationData?.image.split(".").pop();

      navigator.mediaSession.metadata = new window.MediaMetadata({
        title:
          allStations.find((s) => s.id === currentStation)?.name || "Radio",
        artist: (() => {
          const station = allStations.find((s) => s.id === currentStation);
          if (station?.country === "UK") {
            return "UK Station";
          } else if (station?.country === "RO") {
            return "Radio din România";
          } else {
            return "Radio";
          }
        })(),
        album: loadingStation ? "Loading station..." : "",
        artwork: [
          {
            src: allStations.find((s) => s.id === currentStation)?.image || "",
            sizes: "96x96",
            type: `image/${imageType}`,
          },
        ],
      });

      navigator.mediaSession.setActionHandler("play", () => {
        if (!isPlaying && currentStation !== null) {
          playStation(allStations.find((s) => s.id === currentStation));
        }
      });

      navigator.mediaSession.setActionHandler("pause", () => {
        if (isPlaying) {
          stopPreviousStream();
        }
      });

      navigator.mediaSession.setActionHandler("previoustrack", previousStation);
      navigator.mediaSession.setActionHandler("nexttrack", nextStation);
    }
  }, [
    currentStation,
    isPlaying,
    nextStation,
    playStation,
    previousStation,
    loadingStation,
    allStations,
  ]);

  const toggleFavorite = (e, station) => {
    e.preventDefault();
    e.stopPropagation();

    // Determine if the fade-out effect should be applied based on the region
    const isUK = station.country === "UK";
    const isRO = station.country === "RO";

    const shouldApplyFadeOut = (isUK && !showMoreUK) || (isRO && !showMoreRO);

    // If the station is being unfavorited and should apply fade-out
    if (station.favorite && shouldApplyFadeOut) {
      setRemovingStation(station.id);

      setTimeout(() => {
        const updatedStations = allStations.map((s) =>
          s.id === station.id ? { ...s, favorite: !s.favorite } : s
        );

        const updatedFavoriteStations = {
          UK: updatedStations.filter(
            (station) => station.country === "UK" && station.favorite
          ),
          RO: updatedStations.filter(
            (station) => station.country === "RO" && station.favorite
          ),
        };

        setAllStations(updatedStations);
        setFavoriteStations(updatedFavoriteStations);
        saveFavoritesToStorage(updatedFavoriteStations);

        setRemovingStation(null); // Reset the state after the fade-out
      }, 500); // Match the CSS animation duration (0.5s)
    } else {
      // Directly update the stations without the fade-out when favoriting or if all favorites are visible
      const updatedStations = allStations.map((s) =>
        s.id === station.id ? { ...s, favorite: !s.favorite } : s
      );

      const updatedFavoriteStations = {
        UK: updatedStations.filter(
          (station) => station.country === "UK" && station.favorite
        ),
        RO: updatedStations.filter(
          (station) => station.country === "RO" && station.favorite
        ),
      };

      setAllStations(updatedStations);
      setFavoriteStations(updatedFavoriteStations);
      saveFavoritesToStorage(updatedFavoriteStations);
    }
  };

  return (
    <div className="app-container">
      <div className="station-grid">
        {displayedUKStations.map((station) => (
          <div
            className={`wrapper ${
              removingStation === station.id ? "fade-out" : ""
            }`}
            key={station.id}
          >
            <div
              className={`station-tile ${
                currentStation === station.id && isPlaying ? "playing" : ""
              } ${
                loadingStation && loadingStation !== station.id
                  ? "disabled"
                  : ""
              }`}
              onClick={() => {
                if (!loadingStation || loadingStation === station.id) {
                  playStation(station);
                }
              }}
            >
              <span
                className="favorite"
                onClick={(e) => toggleFavorite(e, station)}
              >
                {station.favorite ? "⭐" : <StarOffIcon size={16} />}
              </span>
              <img
                src={station.image}
                alt={station.name}
                className="station-image"
              />
              <div className="icon-container">
                {loadingStation === station.id ? (
                  <Loader size={14} className="spinning" />
                ) : currentStation === station.id && isPlaying ? (
                  <Pause size={14} />
                ) : (
                  <Play size={14} />
                )}
              </div>
            </div>

            <div className="station-content">
              <div className="station-name">{station.name}</div>
            </div>
          </div>
        ))}
      </div>

      <button className="btn btn-uk" onClick={() => setShowMoreUK(!showMoreUK)}>
        <span className="left-ripple"></span>
        <span className="right-ripple"></span>
        {showMoreUK ? "Show UK Favorites" : "Show All UK"}
      </button>

      <div className="station-grid">
        {displayedROStations.map((station) => (
          <div
            className={`wrapper ${
              removingStation === station.id ? "fade-out" : ""
            }`}
            key={station.id}
          >
            <div
              className={`station-tile ${
                currentStation === station.id && isPlaying ? "playing" : ""
              } ${
                loadingStation && loadingStation !== station.id
                  ? "disabled"
                  : ""
              }`}
              onClick={() => {
                if (!loadingStation || loadingStation === station.id) {
                  playStation(station);
                }
              }}
            >
              <span
                className="favorite"
                onClick={(e) => toggleFavorite(e, station)}
              >
                {station.favorite ? "⭐" : <StarOffIcon size={16} />}
              </span>
              <img
                src={station.image}
                alt={station.name}
                className="station-image"
              />
              <div className="icon-container">
                {loadingStation === station.id ? (
                  <Loader size={14} className="spinning" />
                ) : currentStation === station.id && isPlaying ? (
                  <Pause size={14} />
                ) : (
                  <Play size={14} />
                )}
              </div>
            </div>

            <div className="station-content">
              <div className="station-name">{station.name}</div>
            </div>
          </div>
        ))}
      </div>

      <button className="btn btn-ro" onClick={() => setShowMoreRO(!showMoreRO)}>
        <span className="left-ripple"></span>
        <span className="right-ripple"></span>
        {showMoreRO ? "Show RO Favorites" : "Show All RO"}
      </button>

      {currentStation && (
        <div className="bottom-bar">
          <div className="station-info">
            <img
              src={allStations.find((s) => s.id === currentStation)?.image}
              alt={allStations.find((s) => s.id === currentStation)?.name}
              className="bottom-bar-image"
            />
            <span className="bottom-bar-name">
              {allStations.find((s) => s.id === currentStation)?.name}
            </span>
          </div>
          {currentStation && isPlaying && (
            <ul className="wave-menu">
              <li style={{ backgroundColor: colors[0] }}></li>
              <li style={{ backgroundColor: colors[1] }}></li>
              <li style={{ backgroundColor: colors[2] }}></li>
              <li style={{ backgroundColor: colors[0] }}></li>{" "}
              {/* Reuse colors if needed */}
              <li style={{ backgroundColor: colors[1] }}></li>
              <li style={{ backgroundColor: colors[2] }}></li>
              <li style={{ backgroundColor: colors[0] }}></li>
            </ul>
          )}
          {currentStation && (
            <img
              ref={imageRef} // Reference the image for Color Thief
              src={allStations.find((s) => s.id === currentStation)?.image}
              alt="station"
              style={{ display: "none" }} // Hide the image (used only for color extraction)
              crossOrigin="anonymous" // Add this if you are loading images from different origins
            />
          )}
          <div className="bottom-bar-controls">
            <SkipBack
              size={20}
              onClick={() => {
                if (!loadingStation) {
                  previousStation(); // Only allow clicking if no station is loading
                }
              }}
              style={{
                opacity: loadingStation ? 0.5 : 1,
                cursor: loadingStation ? "not-allowed" : "pointer",
              }}
            />
            {loadingStation ? (
              <Loader size={20} className="spinning" />
            ) : isPlaying ? (
              <Pause size={20} onClick={() => stopPreviousStream()} />
            ) : (
              <Play
                size={20}
                onClick={() =>
                  playStation(allStations.find((s) => s.id === currentStation))
                }
              />
            )}
            <SkipForward
              size={20}
              onClick={() => {
                if (!loadingStation) {
                  nextStation(); // Only allow clicking if no station is loading
                }
              }}
              style={{
                opacity: loadingStation ? 0.5 : 1,
                cursor: loadingStation ? "not-allowed" : "pointer",
              }}
            />
          </div>
        </div>
      )}

      <audio ref={audioRef} controls style={{ display: "none" }} />
    </div>
  );
}

export default App;
